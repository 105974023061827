/* Other */
  /* Other */
  section.other-main{ padding: 70px 0px 50px; min-height: calc(100vh - 70px) }
  section.other-main .highlight-box .col-item a{ text-decoration: none; }
  section.other-main .highlight-box .col-item .inner{
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 20px;
    padding: 10px;
    transition: all .3s;
  }
  section.other-main .highlight-box .col-item .inner .icon-column{
    display: flex;
    align-items: center;
    padding: 10px 25px 10px 15px;
  }
  section.other-main .highlight-box .col-item .inner .icon-column img{
    width: 40px;
  }
  section.other-main .highlight-box .col-item .inner .icon-column svg path{
    fill: #000000;
  }
  section.other-main .highlight-box .col-item .inner .info .title{
    font-family: poppins-medium;
    font-size: 17px;
    margin-bottom: 0;
    color: var(--theme-color-primary);
    line-height: 22px;
  }
  section.other-main .highlight-box .col-item .inner .info .title::before{ content: none; }
  section.other-main .highlight-box .col-item .inner:hover{
    transition: all .3s;
    border: 1px solid var(--theme-color-primary-hover);
    box-shadow: 1px 1px 10px -3px #cccccc;
  }

  /* Responsive */
  @media screen and (max-width: 767px)
  {
    section.other-main .title{ font-size: 26px; }
  }