/* Homepage */
  /* Landing Header */
  section.landing-header{ padding: 80px 0px 80px; }
  section.landing-header .bg-head{ height: 500px; }
  section.landing-header .inner-box{ top: 0px; }
  section.landing-header .col-feat-img .frame,
  section.landing-header .col-feat-img .frame .carousel-item{ height: 500px; }
  section.landing-header .col-feat-img .frame .carousel-item::before{ background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(25,35,46,1) 100%); opacity: 1; }
  section.landing-header .col-info .inner{ padding-left: 50px; }
  /* Glo Services */
  section.glo-services{ padding: 30px 0px 90px; }
  section.glo-services .row-services .col-item .item{ padding: 25px 0px; }
  section.glo-services .row-services .col-item .feat-img .frame{ width: 50px; height: 50px; }
  section.glo-services .row-services .col-item .info .title{ font-size: 18px; }
  /* Glo Portfolio */
  section.glo-portfolio{ padding: 90px 0px 90px; }
  section.glo-portfolio .row-portfolio .list-join-company ul{ margin: 0px -5px; padding: 0; flex-flow: wrap; }
  section.glo-portfolio .row-portfolio .list-join-company ul li{ list-style: none; margin: 5px 5px; }
  section.glo-portfolio .row-portfolio .list-join-company ul li a{
    border: 1px solid #000000;
    border-radius: 20px;
    padding: 10px;
    transition: all .3s;
    font-size: 14px;
  }
  section.glo-portfolio .row-portfolio .list-join-company ul li a:hover{ transition: all .3s; border: 1px solid var(--theme-color-primary); box-shadow: 1px 1px 10px -3px #cccccc; }
  section.glo-portfolio .row-portfolio .item .feat-img img{ border-radius: 10px; box-shadow: 1px 1px 10px -3px #cccccc; }
  section.glo-portfolio .row-portfolio .item .info .title{ font-size: 20px; color: #212529; }
  section.glo-portfolio .row-portfolio .item .info .description a{ font-size: 14px; color: #868686; }
  /* Glo Testimonial */
  /* Testimonial */
  section.glo-testimonial .frame {
    background: url('/img/testimonial-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 90px 0px;
  }
  section.glo-testimonial .frame::before {
    content: '';
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .7;
  }
  section.glo-testimonial .title { font-size: 32px; }
  section.glo-testimonial .owl-main{ padding: 80px 0px 70px; }
  section.glo-testimonial .owl-carousel .owl-stage-outer { padding: 20px 10px 20px 10px; margin-right: -10px; }
  section.glo-testimonial .owl-nav button {
    position: absolute;
    top: 20%;
    background-color: #000;
    color: #fff;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  section.glo-testimonial .owl-nav button.owl-prev { left: -25px; }
  section.glo-testimonial .owl-nav button.owl-next { right: -40px; }
  section.glo-testimonial .owl-nav span {
    font-size: 70px;
    position: relative;
    top: -5px;
    color: var(--theme-color-primary);
  }
  section.glo-testimonial .owl-nav button:focus { outline: none; }
  section.glo-testimonial .testi-border {
    border: 1px solid var(--theme-color-primary);
    padding: 20px 20px 50px 20px;
    width: 100%;
    border-radius: 10px;
  }
  section.glo-testimonial .testi-border .description .quote-abs { right: -10px; top: -33px; font-size: 30px; }
  section.glo-testimonial .testi-border .description p { font-size: 14px; }
  section.glo-testimonial .testi-bottom-box .testi-profile{ margin-top: -45px; }
  section.glo-testimonial .testi-bottom-box .testi-profile .img-box,
  section.glo-testimonial .testi-bottom-box .testi-profile .img-box img{ width: 80px; height: 80px; }
  section.glo-testimonial .testi-bottom-box .testi-profile .img-box img{ border-radius: 90px; border: 3px solid var(--theme-color-primary); padding: 0px; }
  section.glo-testimonial .testi-bottom-box .testi-info label { font-size: 13px; }
  /* Glo Join Company */
  section.glo-join-company{ padding: 90px 0px 90px; background: #fafafa; }
  section.glo-join-company .row-inner{ overflow-x: auto; flex-flow: nowrap; }
  section.glo-join-company .row-inner .item{ max-width: 280px; padding: 0px 25px; }
  section.glo-join-company .row-inner .item .title{ color: #212529; font-size: 17px; }
  section.glo-join-company .row-inner .item .info{ font-size: 14px; color: #868686; }

  /* Responsive */
  @media screen and (max-width: 767px)
  {
    /* Header */
    section.landing-header{ padding: 80px 0px 50px; }
    section.landing-header .bg-head{ position: absolute; height: 100%; top: 0; }
    section.landing-header .inner-box{ top: 0px; position: relative !important; } 
    section.landing-header .col-feat-img .frame, 
    section.landing-header .col-feat-img .frame .carousel-item{ height: 300px; }
    section.landing-header .col-feat-img .frame .carousel-item::before{ background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(25, 35, 46, 1) 100%); }
    section.landing-header .container{ padding: 0px 12px; }
    section.landing-header .col-info .inner{ padding: 0px 15px 0px 30px; margin-top: 40px; }
    section.landing-header .col-feat-img{ padding: 0; }
    /* Services */
    section.glo-services{ padding: 90px 0px 90px; }
    section.glo-services .row-services .col-item .feat-img .frame{ width: 50px; height: 50px; }
    section.glo-services .feat-img-highlight{ display: none; }
    /* Testimonial */
    section.glo-testimonial .owl-carousel{ padding: 0px 15px 0px 15px; }
    section.glo-testimonial .owl-carousel .owl-item{ margin-left: -3px; margin-right: 22px !important; }
    section.glo-testimonial .owl-nav button.owl-prev{ left: -15px;  }
    section.glo-testimonial .owl-nav button.owl-next{ right: -15px; }
    /* Join Company */
    section.glo-join-company .row-inner{ justify-content: flex-start !important; }
    section.glo-join-company .row-inner .item{ max-width: 180px; }
  }