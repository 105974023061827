/* CV */
  section.cv-bg-header{ background: var(--theme-color-accent); height: 150px; }
  section.cv-main-profile{ margin-top: -95px; }
  section.cv-main-profile .col-feat-img .frame{ width: 200px; height: 200px; }
  section.cv-main-profile .col-feat-img .frame img{ border-radius: 100%; }
  section.cv-main-profile .col-profile-detail .as{ color: #bdbdbd; }
  section.cv-main-contact-information ul{ flex-wrap: wrap; margin: 0; padding: 0; }
  section.cv-main-contact-information ul li{ list-style: none; padding: 0px 20px; border-right: 2px solid var(--theme-color-primary); }
  section.cv-main-contact-information ul li:last-child{ border-right: none; }
  section.cv-main-content{ padding: 90px 0px; }
  section.cv-main-content .timeline .item{ padding-left: 25px; margin-top: 10px; margin-bottom: 30px; }
  section.cv-main-content .timeline .item::before{
    content: "";
    height: calc(100% + 32px);
    width: 2px;
    background: var(--theme-color-primary);
    left: 4px;
    top: 5px;
    position: absolute;
  }
  section.cv-main-content .timeline .item:last-child::before{ content: none; }
  section.cv-main-content .timeline .item .title{ font-size: 20px; }
  section.cv-main-content .timeline .item .title::before{
    content: "";
    height: 10px;
    width: 10px;
    background: var(--theme-color-primary);
    border-radius: 50%;
    color: #fff;
    position: absolute;
    left: -25px;
    top: 5px;
  }
  section.cv-main-content .timeline .item .subtitle{ font-size: 18px; font-style: italic; }
  section.cv-main-content .timeline .item .subtitle .portfolio-link{ font-size: 15px; }
  section.cv-main-content .round-tag{ flex-flow: wrap; margin: 0px -10px; }
  section.cv-main-content .round-tag .item{
    background: var(--theme-color-accent);
    border: 3px solid var(--theme-color-accent-hover);
    margin: 10px 10px;
    padding: 5px 20px;
    border-radius: 90px;
  }

  /* Responsive */
  @media screen and (max-width: 767px)
  {
    section.cv-main-profile .col-feat-img .frame { width: 150px; height: 150px; }
    section.cv-main-profile .col-profile-detail .as{ font-size: 16px; }
    section.cv-main-contact-information ul{ justify-content: flex-start; }
    section.cv-main-contact-information ul li{ border-right: none; width: 100%; margin-bottom: 5px; }
    section.cv-main-content .timeline .item .subtitle .portfolio-link{ width: 100%; }
    section.cv-main-content .round-tag .item{ margin: 5px 10px; }
  }