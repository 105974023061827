/* Fonts */
@font-face {
  font-family: 'poppins-regular';
  src: url('/fonts/poppins/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'poppins-light';
  src: url('/fonts/poppins/Poppins-Light.ttf');
}
@font-face {
  font-family: 'poppins-medium';
  src: url('/fonts/poppins/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'poppins-semibold';
  src: url('/fonts/poppins/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'maiah-regular';
  src: url('/fonts/maiah/Maiah-Regular.ttf');
}
@font-face {
  font-family: 'maiah-bold';
  src: url('/fonts/maiah/Maiah-Bold.ttf');
}

/* Defined Color Theme */
:root {
  --theme-color-primary: #007ced;
  --theme-color-primary-hover: #0364bf;
  --theme-color-accent: #b5c4d4;
  --theme-color-accent-hover: #a2afbc;
  --theme-color-secondary: #0b345a;
}

/* Global Classess */
  .glo-text-primary{ color: var(--theme-color-primary); }

  .img-obj-cover{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .glo-title{
    font-family: maiah-bold;
    line-height: 40px;
    font-size: 36px;
  }
  .glo-title.medium{ font-size: 32px; }
  .glo-title.small{ font-size: 26px; }
  .glo-description-light{ font-family: poppins-light; font-size: 16px; }
  .glo-description-regular{ font-family: poppins-regular; font-size: 16px; }
  .glo-description-medium{ font-family: poppins-medium; font-size: 16px; }
  .glo-description-semibold{ font-family: poppins-semibold; font-size: 16px; }
  /* Button */
  .glo-btn-box{
    display: flex; 
    flex-flow: wrap; 
    margin-top: 25px; 
    margin-left: -10px;
    margin-right: -10px;
  }
  .glo-btn-box a,
  .glo-btn-box button{ margin: 10px; }
  .glo-btn-primary{
    background: var(--theme-color-primary);
    border: 2px solid var(--theme-color-primary);
    border-radius: 25px;
    color: #ffffff;
    text-decoration: none;
    padding: 11px 25px 10px;
    font-family: 'poppins-medium';
    font-size: 14px;
    transition: all .3s;
  }
  .glo-btn-primary:hover{
    background: var(--theme-color-primary-hover);
    border: 2px solid var(--theme-color-primary-hover);
    color: #ffffff;
    transition: all .3s;
  }
  /* End Button */
  .before-overlay::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: .5;
  }
  /* Theme Scrollbar */
  .glo-theme-scrollbar::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .glo-theme-scrollbar::-webkit-scrollbar
  {
    width: 12px;
    height: 4px;
    background-color: #F5F5F5;
  }
  .glo-theme-scrollbar::-webkit-scrollbar-thumb
  {
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--theme-color-secondary);
  }
  /* End Theme Scrollbar */
/* End Global Classess */

/* Navbar */
  nav{
    box-shadow: 1px 1px 10px -3px #cccccc;
    height: 80px;
    flex-flow: wrap;
    background: #ffffff;
    z-index: 12;
  }
  nav .inner{ height: 70px; }
  nav .identity-col a{
    font-size: 40px;
    text-decoration: none;
    color: #ffffff;
    margin-left: -10px;
  }
  nav .identity-col a img{ width: 200px; margin-top: -10px; }
  nav .identity-col a img.is-mobile{ display: none; width: 180px; margin-top: 0px; }
  nav .menu-col.menu-col-desk{ display: flex; }
  nav .menu-col.menu-col-mob{ display: none !important; }
  nav .menu-col.menu-col-desk ul{ margin-right: -15px; }
  nav .menu-col ul{ margin: 0; padding: 0; }
  nav .menu-col li{ display: inline-block; position: relative; }
  nav .menu-col li a{
    padding: 0px 15px;
    text-decoration: none;
    font-family: 'poppins-medium';
    color: #000000;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all .2s;
    position: relative;
  }
  nav .menu-col li.active a{ color: var(--theme-color-primary); }
  nav .menu-col li a:hover{ color: var(--theme-color-primary); }
  nav .menu-col li a i{
    margin-left: 5px;
    color: #000000;
    transform: rotate(0deg);
    transition: all .2s;
  }
  nav .menu-col li a i.normal-icon-right{
    margin-right: 5px;
    color: #000000;
    transition: all .2s;
  }
  nav .menu-col li ul.child{
    position: absolute;
    padding: 15px 0px;
    background: var(--theme-color-primary);
    min-width: 100%;
    width: max-content;
    display: none;
    transition: all .2s;
  }
  nav .menu-col li ul.child li{
    display: block;
    padding: 10px 10px;  
  }
  nav .menu-col li ul.child li.active,
  nav .menu-col li ul.child li:hover{ 
    background: var(--theme-color-primary-hover); 
    font-weight: bold;
  }
  nav .menu-col li ul.child li a{
    padding: 0;
    color: #ffffff;
  }
  nav .menu-col li:hover a i{
    transform: rotate(181deg);
    color: var(--theme-color-primary);
  }
  nav .menu-col li:hover a i.normal-icon-right{
    transform: none;
  }
  nav .menu-col li:hover ul.child{
    display: block;
    transition: all .2s;
  }
  nav .menu-col li.btn-nav{
    padding: 18px 0px;
    margin-left: 10px;
  }
  nav .menu-col li.btn-nav a{
    margin-top: -5px;
    padding: 20px 20px;
    border-radius: 5px;
    border: 2px solid #000000;
    font-family: 'poppins-light';
    font-size: 16px;
    color: #000000;
  }
  nav .menu-col li.btn-nav a:hover{
    background: var(--theme-color-primary);
    color: #ffffff;
  }
  nav .menu-col-mob .hamburger{
    position: absolute;
    right: 0;
    top: 23px;
    cursor: pointer;
    z-index: 10;
    padding: 10px 15px 5px;
  }
  nav .menu-col-mob .hamburger .bars {
    display: block;
    position: relative;
    width: 28px;
    height: 2px;
    background-color: var(--theme-color-accent);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav .menu-col-mob .hamburger .bars:before {
    top: 9px;
  }
  nav .menu-col-mob .hamburger .bars:after {
    bottom: 9px;
  }
  nav .menu-col-mob .hamburger .bars:before,
  nav .menu-col-mob .hamburger .bars:after {
    position: absolute;
    content: " ";
    width: 100%;
    height: 2px;
    background-color: var(--theme-color-accent);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav .menu-col-mob.open .hamburger .bars {
    background-color: transparent;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav .menu-col-mob.open .hamburger .bars::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: var(--theme-color-primary);
  }
  nav .menu-col-mob.open .hamburger .bars::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: var(--theme-color-primary);
  }
  nav .menu-col-mob .menu {
    width: 90%;
    height: 100%;
    padding: 60px 20px 0;
    background-color: #000000;
    box-shadow: 0 0 5px rgb(0 0 0 / 70%);
    position: fixed;
    right: -100%;
    top: 0;
    opacity: 0;
    z-index: 9;
    margin-top: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav .menu-col-mob.open .menu {
    right: 0;
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav .menu-col-mob .menu .inner{
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: block;
    padding-bottom: 40px;
  }
  nav .menu-col-mob .menu li{
    list-style: none;
    display: block;
  }
  nav .menu-col-mob .menu li.list-w-collapse{ margin-bottom: 10px; }
  nav .menu-col-mob .menu li .btn-collapse-a{
    text-decoration: none;
    display: block;
    padding-left: 0;
    padding-right: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffffff;
    font-size: 14px;
    position: relative;
  }
  nav .menu-col-mob .menu li .btn-collapse-a.collapsed::after{
    transform: rotate(0deg);
  }
  nav .menu-col-mob .menu li .btn-collapse-a::after{
    content: "\f107";
    font: normal normal normal 28px/1 FontAwesome;
    position: absolute;
    top: 9px;
    right: 10px;
    color: #ffffff;
    transform: rotate(181deg);
    transition: all .3s;
  }
  nav .menu-col-mob .menu li .card-body{
    background-color: var(--theme-color-primary-hover);
    padding: 15px 10px;
  }
  nav .menu-col-mob .menu li .card-body ul{
    padding: 0;
    margin-bottom: 0;
  }
  nav .menu-col-mob .menu li .card-body ul li{ margin-bottom: 15px; }
  nav .menu-col-mob .menu li .card-body ul li:last-child{ margin-bottom: 0; }
  nav .menu-col-mob .menu li .card-body ul li a{
    padding-left: 0;
    color: #ffffff;
    font-size: 14px;
  }
  nav .menu-col-mob .menu .auth-box{
    position: absolute;
    top: 15px;
    left: 20px;
    right: 50px;
    display: flex;
  }
  nav .menu-col-mob .menu li.list-std-acc{ margin-bottom: 0px; }
  nav .menu-col-mob .menu li.list-std{ margin-bottom: 10px; }
  nav .menu-col-mob .menu li.list-std-acc a{
    font-family: 'montserrat-medium';
    color: #2196f7;
    font-size: 14px;
    padding: 0;
  }
  nav .menu-col-mob .menu li.list-std.active a{ color: #2196f7; }
  nav .menu-col-mob .menu li.list-std-acc:first-child{ margin-right: 20px; }
  nav .menu-col-mob .menu li:hover a i{ transform: rotate(0deg); }
  nav .menu-col-mob .menu li.list-std a{
    color: #ffffff;
    font-size: 14px;
    padding: 0;
  }
  nav .menu-col-mob .menu li.list-std-acc.list-w-collapse {
    margin-bottom: 0px;
  }
  nav .menu-col-mob .menu li.list-std-acc.list-w-collapse a{
    color: #ffffff;
    border-bottom: none;
    position: relative;
    padding-right: 40px;
    display: flex;
    align-items: center;
  }
  nav .menu-col-mob .menu li.list-std-acc-collapse .card-body{ border-top: 1px solid #323b99; }
  nav .menu-col-mob .menu li.list-std-acc .btn-collapse-a::after{ top: 1px; }
/* End Navbar */

/* Navigation */
  .navigation{ padding-top: 110px; }
  .navigation ul{ margin: 0; padding: 0; }
  .navigation ul li{ 
    list-style: none; 
    padding-right: 35px;
    position: relative;
    font-family: poppins-medium;
    font-size: 14px;
  }
  .navigation ul li::after{
    content: '/';
    position: absolute;
    top: 2px;
    right: 13px;
    font-family: poppins-medium;
    font-size: 14px;
  }
  .navigation ul li:last-child:after{ content: none; }
  .navigation ul li a{
    text-decoration: none;
    font-family: poppins-medium;
    font-size: 14px;
    color: #bdbdbd;
  }
  .navigation ul li a:hover{ color: var(--theme-color-primary); }
  .navigation ul li a i{ margin-right: 5px; }
/* End Navigation */

/* Glo Fast Contact */
section.glo-fast-contact{ background: var(--theme-color-secondary); padding: 70px 0px; }
section.glo-fast-contact .col-main{ text-align: center; }

/* Article Section */
section.glob-article{
  padding: 70px 0px 70px;
}
section.glob-article .col-head .title{
  font-family: maiah-bold;
  line-height: 40px;
  font-size: 32px;
  position: relative;
}
section.glob-article .col-head .title span{
  position: relative;
  background: #ffffff;
  padding-right: 12px;
}
section.glob-article .col-head .title::before{
  content: '';
  position: absolute;
  top: 0;
  margin-top: 21px;
  width: 100%;
  height: 2px;
  background: var(--theme-color-primary);
}
section.glob-article .col-primary{ margin-bottom: 25px; }
section.glob-article .col-primary .item{
  min-height: 250px;
  border-radius: 10px;
  position: relative;
  display: inline;
}
section.glob-article .col-std .item{
  border-radius: 10px;
  position: relative;
}
section.glob-article .col-std{ margin-bottom: 25px; }
section.glob-article .col-std,
section.glob-article .col-std .item{ height: 250px; }
section.glob-article .col-primary .item .frame{ min-height: 250px; height: 100%; }
section.glob-article .col-std .item .frame{ height: 250px; }
section.glob-article .col-primary .item .frame img,
section.glob-article .col-std .item .frame img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
section.glob-article .col-primary .item .abs-content,
section.glob-article .col-std .item .abs-content{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: linear-gradient(to bottom,rgb(0 0 0 / 50%),#000);
  border-radius: 0px 0px 10px 10px;
}
section.glob-article .col-primary .item .abs-content .date,
section.glob-article .col-std .item .abs-content .date{
  font-family: poppins-regular;
  font-size: 14px;
  color: #ffffff;
}
section.glob-article .col-primary .item .abs-content .title,
section.glob-article .col-std .item .abs-content .title{
  font-family: maiah-bold;
  line-height: 28px;
  font-size: 21px;
  position: relative;
  color: #ffffff;
}
section.glob-article .btn-box{
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
section.glob-article .btn-box .btn-see-more{
  background: var(--theme-color-primary);
  border: 2px solid var(--theme-color-primary);
  border-radius: 25px;
  color: #ffffff;
  text-decoration: none;
  padding: 11px 25px 10px;
  font-family: 'poppins-medium';
  font-size: 14px;
  transition: all .3s;
}
section.glob-article .btn-box .btn-see-more:hover{
  background: var(--theme-color-primary-hover);
  border: 2px solid var(--theme-color-primary-hover);
  transition: all .3s;
}



/* footer */
footer{ background: #000000; padding: 20px 0px; }
footer .footer-navigation ul{
  padding: 0;
  margin: 0;
  flex-flow: wrap;
}
footer .footer-navigation ul li{ list-style: none; margin: 5px 15px; }
footer .footer-navigation ul li::after{
  content: '|';
  position: absolute;
  right: -17px;
  color: #ffffff;
}
footer .footer-navigation ul li:last-child:after{ content: none; }
footer .footer-navigation ul li a{
  font-size: 14px;
  text-decoration: none;
  transition: all .2s;
}
footer .footer-navigation ul li a:hover{ color: var(--theme-color-primary-hover); transition: all .2s;
}
footer .cp{ font-size: 14px; }

/* Responsive */
@media screen and (max-width: 1100px) {
  /* Navbar */
  nav .identity-col a img.is-desktop{ display: none !important; }
  nav .identity-col a img.is-mobile{ display: block !important; }
  nav .menu-col{ padding-right: 43px; }
  nav .menu-col li a{ font-size: 12px; }
  nav .menu-col.menu-col-desk{ display: none !important; }
  nav .menu-col.menu-col-mob{ display: flex !important; }
  nav .col-inner{ padding: 0px; }
}

@media screen and (max-width: 767px)
{
  /*  */
  .container{ padding: 0px 30px; }

  /* Navigation */
  .navigation ul{ flex-flow: wrap; }

  /* Global Classess */
  .glo-title{ line-height: 30px; font-size: 26px; }
  .glo-title.small{ font-size: 22px; }
  .glo-description-light,
  .glo-description-regular,
  .glo-description-medium,
  .glo-description-semibold{ font-size: 14px; }

  /* Article Section */
  section.glob-article .col-head .title{ font-size: 26px; }
  section.glob-article .col-primary .item .abs-content .date, 
  section.glob-article .col-std .item .abs-content .date{ font-size: 12px; }
  section.glob-article .col-primary .item .abs-content .title, 
  section.glob-article .col-std .item .abs-content .title{
    line-height: 22px;
    font-size: 17px;
  }

  /* Testimonial */
  section.glo-testimonial .frame{ padding: 70px 0px; }
  section.glo-testimonial .title{
    line-height: 30px;
    font-size: 26px;
  }
  

  /* Footer */
  footer .footer-navigation ul{ justify-content: flex-start; flex-flow: wrap; }
  footer .footer-navigation ul li{ width: 100%; margin: 5px 0px; }
  footer .footer-navigation ul li::after{ content: none; }
}