/* Portfolio */
  /* Detail */
  section.portfolio-detail{ padding: 70px 0px 70px; }
  section.portfolio-detail .col-primary .feat-img img{ border-radius: 10px; box-shadow: 1px 1px 10px -3px #cccccc; }
  section.portfolio-detail .col-info{ padding-left: 35px; }
  section.portfolio-detail .col-info .row-details{ margin-top: 30px; }

  /* Responsive */
  @media screen and (max-width: 767px)
  {
    /* Detail */
    section.portfolio-detail .col-info{ margin-top: 34px; padding-left: 15px; }
  }